import { default as adminsktw96SRoeKMeta } from "/tmp/build_3152f55f/pages/administration/admins.vue?macro=true";
import { default as _91id_93lppdvzVAf7Meta } from "/tmp/build_3152f55f/pages/administration/billing/[id].vue?macro=true";
import { default as indexzlYtHEFZAyMeta } from "/tmp/build_3152f55f/pages/administration/billing/index.vue?macro=true";
import { default as bookingsYdxDph5hQ9Meta } from "/tmp/build_3152f55f/pages/administration/bookings.vue?macro=true";
import { default as exportsjnjy1IshhcMeta } from "/tmp/build_3152f55f/pages/administration/exports.vue?macro=true";
import { default as fidelitykiupEIv8qZMeta } from "/tmp/build_3152f55f/pages/administration/fidelity.vue?macro=true";
import { default as invoicesaBM7auA1glMeta } from "/tmp/build_3152f55f/pages/administration/invoices.vue?macro=true";
import { default as policyksoAE3xUYtMeta } from "/tmp/build_3152f55f/pages/administration/organization/policy.vue?macro=true";
import { default as _91id_93E2QkIvWqDdMeta } from "/tmp/build_3152f55f/pages/administration/payment/[id].vue?macro=true";
import { default as index9LMA3eGdEiMeta } from "/tmp/build_3152f55f/pages/administration/payment/index.vue?macro=true";
import { default as co2KFr4fy7NX6Meta } from "/tmp/build_3152f55f/pages/administration/statistics/co2.vue?macro=true";
import { default as indexfkXBWO8zb9Meta } from "/tmp/build_3152f55f/pages/administration/statistics/index.vue?macro=true";
import { default as indexP485b5od6HMeta } from "/tmp/build_3152f55f/pages/administration/teams/[teamId]/index.vue?macro=true";
import { default as invitationGQih18kwPcMeta } from "/tmp/build_3152f55f/pages/administration/teams/[teamId]/invitation.vue?macro=true";
import { default as indexJZLjo5rzpaMeta } from "/tmp/build_3152f55f/pages/administration/teams/[teamId]/policy/index.vue?macro=true";
import { default as modifyTXWJiJYSwlMeta } from "/tmp/build_3152f55f/pages/administration/teams/[teamId]/policy/modify.vue?macro=true";
import { default as validatorsx5uwIwUincMeta } from "/tmp/build_3152f55f/pages/administration/teams/[teamId]/validators.vue?macro=true";
import { default as _91teamId_93gJVhqaimkAMeta } from "/tmp/build_3152f55f/pages/administration/teams/[teamId].vue?macro=true";
import { default as index0KSXpx2mupMeta } from "/tmp/build_3152f55f/pages/administration/teams/index.vue?macro=true";
import { default as trip_45labelsrfGL9ypFNMMeta } from "/tmp/build_3152f55f/pages/administration/trip-labels.vue?macro=true";
import { default as administrationl86GV9INyFMeta } from "/tmp/build_3152f55f/pages/administration.vue?macro=true";
import { default as swileJuZTAfOiLEMeta } from "/tmp/build_3152f55f/pages/auth/swile.vue?macro=true";
import { default as indexInD8CELUiUMeta } from "/tmp/build_3152f55f/pages/bookings/index.vue?macro=true";
import { default as indexnw46SobOZAMeta } from "/tmp/build_3152f55f/pages/bookings/validator/index.vue?macro=true";
import { default as pendingXvGB16wWrsMeta } from "/tmp/build_3152f55f/pages/bookings/validator/pending.vue?macro=true";
import { default as _91id_93oVGDPEiQHJMeta } from "/tmp/build_3152f55f/pages/car/[id].vue?macro=true";
import { default as fidelityY43tvKs3yhMeta } from "/tmp/build_3152f55f/pages/fidelity.vue?macro=true";
import { default as exchangeZR2LmWepXrMeta } from "/tmp/build_3152f55f/pages/flight/[id]/exchange.vue?macro=true";
import { default as faresFBOcf6mN9yMeta } from "/tmp/build_3152f55f/pages/flight/[id]/fares.vue?macro=true";
import { default as indexxZJboqAtKYMeta } from "/tmp/build_3152f55f/pages/flight/[id]/index.vue?macro=true";
import { default as forgotOXjd5pu1gmMeta } from "/tmp/build_3152f55f/pages/forgot.vue?macro=true";
import { default as get_45swile_45appsoVv8ND4uDMeta } from "/tmp/build_3152f55f/pages/get-swile-app.vue?macro=true";
import { default as helphp12SEGhpLMeta } from "/tmp/build_3152f55f/pages/help.vue?macro=true";
import { default as _91id_93R6UXO29tdNMeta } from "/tmp/build_3152f55f/pages/hotel/[id].vue?macro=true";
import { default as _91id_938rNAG4ZrLIMeta } from "/tmp/build_3152f55f/pages/impersonate/[id].vue?macro=true";
import { default as indexgfCe1NnVdeMeta } from "/tmp/build_3152f55f/pages/index.vue?macro=true";
import { default as _91suffix_93t1uP3L2NoBMeta } from "/tmp/build_3152f55f/pages/invitation/[suffix].vue?macro=true";
import { default as _91token_93iOaEjckvhvMeta } from "/tmp/build_3152f55f/pages/invite/[token].vue?macro=true";
import { default as _91id_93vw83P3GcpQMeta } from "/tmp/build_3152f55f/pages/journeys/[id].vue?macro=true";
import { default as indexpRSvZeRdJlMeta } from "/tmp/build_3152f55f/pages/journeys/index.vue?macro=true";
import { default as membersX7xoVF9CPxMeta } from "/tmp/build_3152f55f/pages/myteam/members.vue?macro=true";
import { default as policy6uP5pA7GzjMeta } from "/tmp/build_3152f55f/pages/myteam/policy.vue?macro=true";
import { default as validators934gzaqT1LMeta } from "/tmp/build_3152f55f/pages/myteam/validators.vue?macro=true";
import { default as myteamOgYLguQsmJMeta } from "/tmp/build_3152f55f/pages/myteam.vue?macro=true";
import { default as new_45userXssRpJ5irYMeta } from "/tmp/build_3152f55f/pages/new-user.vue?macro=true";
import { default as passwordzRBvbariOHMeta } from "/tmp/build_3152f55f/pages/password.vue?macro=true";
import { default as indexxu4ONJSgr4Meta } from "/tmp/build_3152f55f/pages/search/car/[search_id]/index.vue?macro=true";
import { default as indexfO43ZdnTc0Meta } from "/tmp/build_3152f55f/pages/search/car/index.vue?macro=true";
import { default as _91search_id_93N8sAi71Kk3Meta } from "/tmp/build_3152f55f/pages/search/car/init/[search_id].vue?macro=true";
import { default as _91search_id_93el3i3kvE0BMeta } from "/tmp/build_3152f55f/pages/search/flight/[search_id].vue?macro=true";
import { default as indexshCyO7RCTJMeta } from "/tmp/build_3152f55f/pages/search/flight/index.vue?macro=true";
import { default as index5S6imprWbLMeta } from "/tmp/build_3152f55f/pages/search/hotel/[search_id]/index.vue?macro=true";
import { default as _91hotelIds_93dIHBo8P2llMeta } from "/tmp/build_3152f55f/pages/search/hotel/[search_id]/rooms/[hotelIds].vue?macro=true";
import { default as indexahw9TxdkS2Meta } from "/tmp/build_3152f55f/pages/search/hotel/index.vue?macro=true";
import { default as _91search_id_93sKV0NV7nQdMeta } from "/tmp/build_3152f55f/pages/search/train/[search_id].vue?macro=true";
import { default as indexumjttSe89AMeta } from "/tmp/build_3152f55f/pages/search/train/index.vue?macro=true";
import { default as searchxYP0SmaTKBMeta } from "/tmp/build_3152f55f/pages/search.vue?macro=true";
import { default as indexHh0OCpGfzEMeta } from "/tmp/build_3152f55f/pages/teams/[teamId]/index.vue?macro=true";
import { default as invitationqgUgDhWUxqMeta } from "/tmp/build_3152f55f/pages/teams/[teamId]/invitation.vue?macro=true";
import { default as policy9QH11MFyHBMeta } from "/tmp/build_3152f55f/pages/teams/[teamId]/policy.vue?macro=true";
import { default as validatorsqmzQ3ZrWazMeta } from "/tmp/build_3152f55f/pages/teams/[teamId]/validators.vue?macro=true";
import { default as _91teamId_93M19atr4eWwMeta } from "/tmp/build_3152f55f/pages/teams/[teamId].vue?macro=true";
import { default as indexWaI9TZl7aBMeta } from "/tmp/build_3152f55f/pages/teams/index.vue?macro=true";
import { default as teamsXbhNcgEnYRMeta } from "/tmp/build_3152f55f/pages/teams.vue?macro=true";
import { default as index5WAYeGWgOSMeta } from "/tmp/build_3152f55f/pages/train/[id]/cancel/index.vue?macro=true";
import { default as indexDEK3dxccNQMeta } from "/tmp/build_3152f55f/pages/train/[id]/exchange/changeable-selection/index.vue?macro=true";
import { default as indexs8Ok3NiY32Meta } from "/tmp/build_3152f55f/pages/train/[id]/exchange/section-selection/index.vue?macro=true";
import { default as indexlfTkLNVlv6Meta } from "/tmp/build_3152f55f/pages/train/[id]/exchange/train-selection/index.vue?macro=true";
import { default as indexsbyyKCWXa1Meta } from "/tmp/build_3152f55f/pages/train/[id]/index.vue?macro=true";
import { default as driver_45licensejInImcmMypMeta } from "/tmp/build_3152f55f/pages/users/[id]/driver-license.vue?macro=true";
import { default as fidelityAFdoKWC4pMMeta } from "/tmp/build_3152f55f/pages/users/[id]/fidelity.vue?macro=true";
import { default as indexjhFOftlXZcMeta } from "/tmp/build_3152f55f/pages/users/[id]/index.vue?macro=true";
import { default as passportJlpczmkumfMeta } from "/tmp/build_3152f55f/pages/users/[id]/passport.vue?macro=true";
import { default as passwordKgh5aMNKCGMeta } from "/tmp/build_3152f55f/pages/users/[id]/password.vue?macro=true";
import { default as _91id_9343YCTMR4qIMeta } from "/tmp/build_3152f55f/pages/users/[id].vue?macro=true";
import { default as index4xPwLN8BMTMeta } from "/tmp/build_3152f55f/pages/users/index.vue?macro=true";
import { default as vouchershkAElQQkElMeta } from "/tmp/build_3152f55f/pages/vouchers.vue?macro=true";
import { default as component_45stubaUf54YGKqWMeta } from "/tmp/build_3152f55f/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubaUf54YGKqW } from "/tmp/build_3152f55f/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "administration",
    path: "/administration",
    meta: administrationl86GV9INyFMeta || {},
    component: () => import("/tmp/build_3152f55f/pages/administration.vue"),
    children: [
  {
    name: "administration-admins",
    path: "admins",
    component: () => import("/tmp/build_3152f55f/pages/administration/admins.vue")
  },
  {
    name: "administration-billing-id",
    path: "billing/:id()",
    component: () => import("/tmp/build_3152f55f/pages/administration/billing/[id].vue")
  },
  {
    name: "administration-billing",
    path: "billing",
    component: () => import("/tmp/build_3152f55f/pages/administration/billing/index.vue")
  },
  {
    name: "administration-bookings",
    path: "bookings",
    component: () => import("/tmp/build_3152f55f/pages/administration/bookings.vue")
  },
  {
    name: "administration-exports",
    path: "exports",
    component: () => import("/tmp/build_3152f55f/pages/administration/exports.vue")
  },
  {
    name: "administration-fidelity",
    path: "fidelity",
    component: () => import("/tmp/build_3152f55f/pages/administration/fidelity.vue")
  },
  {
    name: "administration-invoices",
    path: "invoices",
    component: () => import("/tmp/build_3152f55f/pages/administration/invoices.vue")
  },
  {
    name: "administration-organization-policy",
    path: "organization/policy",
    component: () => import("/tmp/build_3152f55f/pages/administration/organization/policy.vue")
  },
  {
    name: "administration-payment-id",
    path: "payment/:id()",
    component: () => import("/tmp/build_3152f55f/pages/administration/payment/[id].vue")
  },
  {
    name: "administration-payment",
    path: "payment",
    component: () => import("/tmp/build_3152f55f/pages/administration/payment/index.vue")
  },
  {
    name: "administration-statistics-co2",
    path: "statistics/co2",
    component: () => import("/tmp/build_3152f55f/pages/administration/statistics/co2.vue")
  },
  {
    name: "administration-statistics",
    path: "statistics",
    component: () => import("/tmp/build_3152f55f/pages/administration/statistics/index.vue")
  },
  {
    name: _91teamId_93gJVhqaimkAMeta?.name,
    path: "teams/:teamId()",
    component: () => import("/tmp/build_3152f55f/pages/administration/teams/[teamId].vue"),
    children: [
  {
    name: "administration-teams-teamId",
    path: "",
    meta: indexP485b5od6HMeta || {},
    component: () => import("/tmp/build_3152f55f/pages/administration/teams/[teamId]/index.vue")
  },
  {
    name: "administration-teams-teamId-invitation",
    path: "invitation",
    component: () => import("/tmp/build_3152f55f/pages/administration/teams/[teamId]/invitation.vue")
  },
  {
    name: "administration-teams-teamId-policy",
    path: "policy",
    component: () => import("/tmp/build_3152f55f/pages/administration/teams/[teamId]/policy/index.vue")
  },
  {
    name: "administration-teams-teamId-policy-modify",
    path: "policy/modify",
    meta: modifyTXWJiJYSwlMeta || {},
    component: () => import("/tmp/build_3152f55f/pages/administration/teams/[teamId]/policy/modify.vue")
  },
  {
    name: "administration-teams-teamId-validators",
    path: "validators",
    component: () => import("/tmp/build_3152f55f/pages/administration/teams/[teamId]/validators.vue")
  }
]
  },
  {
    name: "administration-teams",
    path: "teams",
    component: () => import("/tmp/build_3152f55f/pages/administration/teams/index.vue")
  },
  {
    name: "administration-trip-labels",
    path: "trip-labels",
    component: () => import("/tmp/build_3152f55f/pages/administration/trip-labels.vue")
  }
]
  },
  {
    name: "auth-swile",
    path: "/auth/swile",
    meta: swileJuZTAfOiLEMeta || {},
    component: () => import("/tmp/build_3152f55f/pages/auth/swile.vue")
  },
  {
    name: "bookings",
    path: "/bookings",
    component: () => import("/tmp/build_3152f55f/pages/bookings/index.vue")
  },
  {
    name: "bookings-validator",
    path: "/bookings/validator",
    meta: indexnw46SobOZAMeta || {},
    component: () => import("/tmp/build_3152f55f/pages/bookings/validator/index.vue")
  },
  {
    name: "bookings-validator-pending",
    path: "/bookings/validator/pending",
    meta: pendingXvGB16wWrsMeta || {},
    component: () => import("/tmp/build_3152f55f/pages/bookings/validator/pending.vue")
  },
  {
    name: "car-id",
    path: "/car/:id()",
    component: () => import("/tmp/build_3152f55f/pages/car/[id].vue")
  },
  {
    name: "fidelity",
    path: "/fidelity",
    component: () => import("/tmp/build_3152f55f/pages/fidelity.vue")
  },
  {
    name: "flight-id-exchange",
    path: "/flight/:id()/exchange",
    component: () => import("/tmp/build_3152f55f/pages/flight/[id]/exchange.vue")
  },
  {
    name: "flight-id-fares",
    path: "/flight/:id()/fares",
    component: () => import("/tmp/build_3152f55f/pages/flight/[id]/fares.vue")
  },
  {
    name: "flight-id",
    path: "/flight/:id()",
    component: () => import("/tmp/build_3152f55f/pages/flight/[id]/index.vue")
  },
  {
    name: "forgot",
    path: "/forgot",
    meta: forgotOXjd5pu1gmMeta || {},
    component: () => import("/tmp/build_3152f55f/pages/forgot.vue")
  },
  {
    name: "get-swile-app",
    path: "/get-swile-app",
    meta: get_45swile_45appsoVv8ND4uDMeta || {},
    component: () => import("/tmp/build_3152f55f/pages/get-swile-app.vue")
  },
  {
    name: "help",
    path: "/help",
    component: () => import("/tmp/build_3152f55f/pages/help.vue")
  },
  {
    name: "hotel-id",
    path: "/hotel/:id()",
    component: () => import("/tmp/build_3152f55f/pages/hotel/[id].vue")
  },
  {
    name: "impersonate-id",
    path: "/impersonate/:id()",
    component: () => import("/tmp/build_3152f55f/pages/impersonate/[id].vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexgfCe1NnVdeMeta || {},
    component: () => import("/tmp/build_3152f55f/pages/index.vue")
  },
  {
    name: "invitation-suffix",
    path: "/invitation/:suffix()",
    meta: _91suffix_93t1uP3L2NoBMeta || {},
    component: () => import("/tmp/build_3152f55f/pages/invitation/[suffix].vue")
  },
  {
    name: "invite-token",
    path: "/invite/:token()",
    meta: _91token_93iOaEjckvhvMeta || {},
    component: () => import("/tmp/build_3152f55f/pages/invite/[token].vue")
  },
  {
    name: "journeys-id",
    path: "/journeys/:id()",
    component: () => import("/tmp/build_3152f55f/pages/journeys/[id].vue")
  },
  {
    name: "journeys",
    path: "/journeys",
    component: () => import("/tmp/build_3152f55f/pages/journeys/index.vue")
  },
  {
    name: "myteam",
    path: "/myteam",
    component: () => import("/tmp/build_3152f55f/pages/myteam.vue"),
    children: [
  {
    name: "myteam-members",
    path: "members",
    component: () => import("/tmp/build_3152f55f/pages/myteam/members.vue")
  },
  {
    name: "myteam-policy",
    path: "policy",
    component: () => import("/tmp/build_3152f55f/pages/myteam/policy.vue")
  },
  {
    name: "myteam-validators",
    path: "validators",
    component: () => import("/tmp/build_3152f55f/pages/myteam/validators.vue")
  }
]
  },
  {
    name: "new-user",
    path: "/new-user",
    meta: new_45userXssRpJ5irYMeta || {},
    component: () => import("/tmp/build_3152f55f/pages/new-user.vue")
  },
  {
    name: "password",
    path: "/password",
    meta: passwordzRBvbariOHMeta || {},
    component: () => import("/tmp/build_3152f55f/pages/password.vue")
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/tmp/build_3152f55f/pages/search.vue"),
    children: [
  {
    name: "search-car-search_id",
    path: "car/:search_id()",
    component: () => import("/tmp/build_3152f55f/pages/search/car/[search_id]/index.vue")
  },
  {
    name: "search-car",
    path: "car",
    component: () => import("/tmp/build_3152f55f/pages/search/car/index.vue")
  },
  {
    name: "search-car-init-search_id",
    path: "car/init/:search_id()",
    component: () => import("/tmp/build_3152f55f/pages/search/car/init/[search_id].vue")
  },
  {
    name: "search-flight-search_id",
    path: "flight/:search_id()",
    component: () => import("/tmp/build_3152f55f/pages/search/flight/[search_id].vue")
  },
  {
    name: "search-flight",
    path: "flight",
    component: () => import("/tmp/build_3152f55f/pages/search/flight/index.vue")
  },
  {
    name: "search-hotel-search_id",
    path: "hotel/:search_id()",
    component: () => import("/tmp/build_3152f55f/pages/search/hotel/[search_id]/index.vue")
  },
  {
    name: "search-hotel-search_id-rooms-hotelIds",
    path: "hotel/:search_id()/rooms/:hotelIds()",
    component: () => import("/tmp/build_3152f55f/pages/search/hotel/[search_id]/rooms/[hotelIds].vue")
  },
  {
    name: "search-hotel",
    path: "hotel",
    component: () => import("/tmp/build_3152f55f/pages/search/hotel/index.vue")
  },
  {
    name: "search-train-search_id",
    path: "train/:search_id()",
    component: () => import("/tmp/build_3152f55f/pages/search/train/[search_id].vue")
  },
  {
    name: "search-train",
    path: "train",
    component: () => import("/tmp/build_3152f55f/pages/search/train/index.vue")
  }
]
  },
  {
    name: teamsXbhNcgEnYRMeta?.name,
    path: "/teams",
    meta: teamsXbhNcgEnYRMeta || {},
    component: () => import("/tmp/build_3152f55f/pages/teams.vue"),
    children: [
  {
    name: _91teamId_93M19atr4eWwMeta?.name,
    path: ":teamId()",
    component: () => import("/tmp/build_3152f55f/pages/teams/[teamId].vue"),
    children: [
  {
    name: "teams-teamId",
    path: "",
    component: () => import("/tmp/build_3152f55f/pages/teams/[teamId]/index.vue")
  },
  {
    name: "teams-teamId-invitation",
    path: "invitation",
    component: () => import("/tmp/build_3152f55f/pages/teams/[teamId]/invitation.vue")
  },
  {
    name: "teams-teamId-policy",
    path: "policy",
    component: () => import("/tmp/build_3152f55f/pages/teams/[teamId]/policy.vue")
  },
  {
    name: "teams-teamId-validators",
    path: "validators",
    component: () => import("/tmp/build_3152f55f/pages/teams/[teamId]/validators.vue")
  }
]
  },
  {
    name: "teams",
    path: "",
    component: () => import("/tmp/build_3152f55f/pages/teams/index.vue")
  }
]
  },
  {
    name: "train-id-cancel",
    path: "/train/:id()/cancel",
    component: () => import("/tmp/build_3152f55f/pages/train/[id]/cancel/index.vue")
  },
  {
    name: "train-id-exchange-changeable-selection",
    path: "/train/:id()/exchange/changeable-selection",
    component: () => import("/tmp/build_3152f55f/pages/train/[id]/exchange/changeable-selection/index.vue")
  },
  {
    name: "train-id-exchange-section-selection",
    path: "/train/:id()/exchange/section-selection",
    component: () => import("/tmp/build_3152f55f/pages/train/[id]/exchange/section-selection/index.vue")
  },
  {
    name: "train-id-exchange-train-selection",
    path: "/train/:id()/exchange/train-selection",
    component: () => import("/tmp/build_3152f55f/pages/train/[id]/exchange/train-selection/index.vue")
  },
  {
    name: "train-id",
    path: "/train/:id()",
    component: () => import("/tmp/build_3152f55f/pages/train/[id]/index.vue")
  },
  {
    name: _91id_9343YCTMR4qIMeta?.name,
    path: "/users/:id()",
    meta: _91id_9343YCTMR4qIMeta || {},
    component: () => import("/tmp/build_3152f55f/pages/users/[id].vue"),
    children: [
  {
    name: "users-id-driver-license",
    path: "driver-license",
    component: () => import("/tmp/build_3152f55f/pages/users/[id]/driver-license.vue")
  },
  {
    name: "users-id-fidelity",
    path: "fidelity",
    component: () => import("/tmp/build_3152f55f/pages/users/[id]/fidelity.vue")
  },
  {
    name: "users-id",
    path: "",
    component: () => import("/tmp/build_3152f55f/pages/users/[id]/index.vue")
  },
  {
    name: "users-id-passport",
    path: "passport",
    component: () => import("/tmp/build_3152f55f/pages/users/[id]/passport.vue")
  },
  {
    name: "users-id-password",
    path: "password",
    component: () => import("/tmp/build_3152f55f/pages/users/[id]/password.vue")
  }
]
  },
  {
    name: "users",
    path: "/users",
    component: () => import("/tmp/build_3152f55f/pages/users/index.vue")
  },
  {
    name: "vouchers",
    path: "/vouchers",
    component: () => import("/tmp/build_3152f55f/pages/vouchers.vue")
  },
  {
    name: "teams-vouchers",
    path: "/teams-vouchers",
    component: () => import("/tmp/build_3152f55f/pages/vouchers.vue")
  },
  {
    name: component_45stubaUf54YGKqWMeta?.name,
    path: "/metrics",
    component: component_45stubaUf54YGKqW
  },
  {
    name: component_45stubaUf54YGKqWMeta?.name,
    path: "/en",
    component: component_45stubaUf54YGKqW
  },
  {
    name: component_45stubaUf54YGKqWMeta?.name,
    path: "/fr",
    component: component_45stubaUf54YGKqW
  },
  {
    name: component_45stubaUf54YGKqWMeta?.name,
    path: "/pt-BR",
    component: component_45stubaUf54YGKqW
  },
  {
    name: component_45stubaUf54YGKqWMeta?.name,
    path: "/en/:pathMatch(.*)",
    component: component_45stubaUf54YGKqW
  },
  {
    name: component_45stubaUf54YGKqWMeta?.name,
    path: "/fr/:pathMatch(.*)",
    component: component_45stubaUf54YGKqW
  },
  {
    name: component_45stubaUf54YGKqWMeta?.name,
    path: "/pt-BR/:pathMatch(.*)",
    component: component_45stubaUf54YGKqW
  }
]